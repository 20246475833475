import React from "react";

import termlyHtml from "./termlyHtml";

const Termly = () => {
  return (
    <div
      className="container py-3"
      dangerouslySetInnerHTML={{ __html: termlyHtml }}
    ></div>
  );
};

export default Termly;
