import React from "react";
import Layout from "../../components/layout";
import Termly from "../../components/privacy-policy/termly";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Termly />
    </Layout>
  );
};

export default PrivacyPolicy;
